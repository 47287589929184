import React, { createContext, useState } from "react";

export const MainContext = createContext({
  isLoadingFinish: false,
});

export const PopUpContext = createContext({
  isPopUpClosed: false,
  setIsPopUpClosed: () => {},
});

export const PopUpContextProvider = ({ children }) => {
  const [isPopUpClosed, setIsPopUpClosed] = useState(false);

  return (
    <PopUpContext.Provider value={{ isPopUpClosed, setIsPopUpClosed }}>
      {children}
    </PopUpContext.Provider>
  );
};
