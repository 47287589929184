exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-doradztwo-prawne-i-podatkowe-js": () => import("./../../../src/pages/oferta/doradztwo-prawne-i-podatkowe.js" /* webpackChunkName: "component---src-pages-oferta-doradztwo-prawne-i-podatkowe-js" */),
  "component---src-pages-oferta-inwestuj-w-nieruchomosci-i-zarabiaj-js": () => import("./../../../src/pages/oferta/inwestuj-w-nieruchomosci-i-zarabiaj.js" /* webpackChunkName: "component---src-pages-oferta-inwestuj-w-nieruchomosci-i-zarabiaj-js" */),
  "component---src-pages-oferta-mieszkania-na-wynajem-i-obsluga-js": () => import("./../../../src/pages/oferta/mieszkania-na-wynajem-i-obsluga.js" /* webpackChunkName: "component---src-pages-oferta-mieszkania-na-wynajem-i-obsluga-js" */),
  "component---src-pages-oferta-mieszkanie-wykonczone-pod-klucz-js": () => import("./../../../src/pages/oferta/mieszkanie-wykonczone-pod-klucz.js" /* webpackChunkName: "component---src-pages-oferta-mieszkanie-wykonczone-pod-klucz-js" */),
  "component---src-pages-oferta-mieszkaniowe-produkty-inwestycyjne-js": () => import("./../../../src/pages/oferta/mieszkaniowe-produkty-inwestycyjne.js" /* webpackChunkName: "component---src-pages-oferta-mieszkaniowe-produkty-inwestycyjne-js" */),
  "component---src-pages-oferta-odbiory-mieszkan-od-dewelopera-js": () => import("./../../../src/pages/oferta/odbiory-mieszkan-od-dewelopera.js" /* webpackChunkName: "component---src-pages-oferta-odbiory-mieszkan-od-dewelopera-js" */),
  "component---src-pages-oferta-stworz-z-mieszkania-produkt-inwestycyjny-i-zarabiaj-na-najmie-znacznie-wiecej-js": () => import("./../../../src/pages/oferta/stworz-z-mieszkania-produkt-inwestycyjny-i-zarabiaj-na-najmie-znacznie-wiecej.js" /* webpackChunkName: "component---src-pages-oferta-stworz-z-mieszkania-produkt-inwestycyjny-i-zarabiaj-na-najmie-znacznie-wiecej-js" */),
  "component---src-pages-oferta-wyszukanie-i-stworzenie-mieszkania-zgodnie-z-oczekiwaniami-js": () => import("./../../../src/pages/oferta/wyszukanie-i-stworzenie-mieszkania-zgodnie-z-oczekiwaniami.js" /* webpackChunkName: "component---src-pages-oferta-wyszukanie-i-stworzenie-mieszkania-zgodnie-z-oczekiwaniami-js" */),
  "component---src-pages-oferta-zarabiaj-ze-mna-js": () => import("./../../../src/pages/oferta/zarabiaj-ze-mna.js" /* webpackChunkName: "component---src-pages-oferta-zarabiaj-ze-mna-js" */),
  "component---src-pages-oferta-zostan-partnerem-biznesowym-i-pomnazaj-oszczednosci-js": () => import("./../../../src/pages/oferta/zostan-partnerem-biznesowym-i-pomnazaj-oszczednosci.js" /* webpackChunkName: "component---src-pages-oferta-zostan-partnerem-biznesowym-i-pomnazaj-oszczednosci-js" */),
  "component---src-pages-polityka-prywatnosci-i-cookies-js": () => import("./../../../src/pages/polityka-prywatnosci-i-cookies.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-i-cookies-js" */)
}

